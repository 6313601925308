
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignupLoginLayout from '@/components/shared/layouts/SignupLoginLayout.vue';
import { EMAIL_REGEX, USERNAME_REGEX, PASSWORD_REGEX } from '@/scripts/shared';
import { Validations } from 'vuelidate-property-decorators';
import { required, sameAs } from 'vuelidate/lib/validators';
import { TranslateResult } from 'vue-i18n';
import * as types from '@/store/types';

const namespace: string = 'user';
const namespaceUser: string = 'error';

@Component({
  components: {
    SignupLoginLayout,
  },
})
export default class ResetPassword extends Vue {
  @Action(types.SET_ERROR, { namespace: namespaceUser })
  setError: any;

  @Action(types.RESET_PASSWORD, { namespace })
  resetPassword: any;

  private password: string = '';

  private repeatPassword: string = '';

  private show = false;

  private token = '';

  @Validations()
  validations = {
    password: {
      required,
      matches(value: string) {
        return PASSWORD_REGEX.test(value);
      },
    },
    repeatPassword: {
      required,
      sameAs: sameAs('password'),
    },
  };

  // On create component check if the id is included and also valid for reset password
  async created() {
    if (!this.$route.query.id) {
      this.$router.push('/login');
      return;
    }
    const { id } = this.$route.query;
    if (id) {
      this.token = id.toString();
    }
  }

  get passwordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.password.$dirty) return errors;

    if (!this.$v.password.required) errors.push(this.$t('required_field'));
    if (!this.$v.password.matches) {
      errors.push(this.$t('password_requirements'));
    }
    return errors;
  }

  get repeatPasswordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.repeatPassword.$dirty) return errors;

    if (!this.$v.repeatPassword.required) {
      errors.push(this.$t('required_field'));
    }
    if (!this.$v.repeatPassword.sameAs) {
      errors.push(this.$t('repeat_password_error'));
    }
    return errors;
  }

  async handleSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.resetPassword({
      token: this.token,
      password: this.password,
    }).then(() => {
      this.$router.push('/login');
    });
  }
}
